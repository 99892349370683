










































































import { Component, Vue, Prop } from "vue-property-decorator";
import { EventBus } from "@/main";
import API from "@/api";

@Component({
  data() {
    return {
      gateways: null,
      loading: true
    };
  },
  async beforeMount() {
    setTimeout(async () => {
      this.$data.gateways = await API.getGateways();
      this.$data.loading = false;
    }, 2000);
  },
  methods: {
    payment(id: number) {
      this.$emit("createPayment", id);
    },
    error() {
      EventBus.$emit(
        "snack-error",
        "Khipu no permite pagos superiores a 5 millones. Si tu inversión es mayor a este monto, puedes abonar y luego pagar lo restante desde tu panel"
      );
    }
  }
})
export default class PaymentGateways extends Vue {
  @Prop({ default: 0 }) amount!: any;
}
