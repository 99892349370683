





































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import { Component, Prop, Vue } from "vue-property-decorator";
import { mapState } from "vuex";
import {
  Configuration,
  Backend,
  SocketPort,
  PusherKey,
  PusherCluster,
  TitleTemplatePublic,
  Countries,
  RedCapitalConfig,
  RedCapital,
  SelectedCountry
} from "@/configuration";
import DniField from "@/components/DniField.vue";
import CurrencyInput from "@/components/Currency.vue";
import ReusableButton from "@/components/ReusableButton.vue";
import ProgressBar from "@/components/ProgressBar.vue";
import { Project } from "@/models/Project";
import { EventBus } from "@/main";
import API from "@/api";
import PaymentGateways from "@/components/common/PaymentGateways.vue";
import moment from "moment";
import { isUserLogin, Uri, AccountTrim } from "@/utils";
import Echo from "laravel-echo";
// @ts-ignore
import Pusher from "pusher-js";

export const pusher = new Pusher(`${PusherKey}`, {
  cluster: `${PusherCluster}`
});

export const EchoInstance = new Echo({
  broadcaster: "socket.io",
  // host: `https://dev.redcapital.pe:6001`,
  host: `${Backend}:${SocketPort}`
});

@Component({
  metaInfo: {
    title: "Simular",
    titleTemplate: TitleTemplatePublic,
    htmlAttrs: {
      // @ts-ignore
      lang: RedCapital[SelectedCountry].Lang
    }
  },
  computed: {
    ...mapState(["user", "cod"]),
    canReserve() {
      return (
        moment(moment().format("YYYY-MM-DD")).unix() <=
        moment(this.$data.project.fecha_entrega_dinero).unix()
      );
    },
    amountToPay() {
      return this.$data.investment.isLastInvestment
        ? this.$data.lastAmount + this.$data.simulation.cobroDerechos
        : this.$data.lastAmount;
    },
    prestamo() {
      return this.$data.investment.isLastInvestment
        ? this.$data.lastAmount
        : this.$data.lastAmount - this.$data.simulation.cobroDerechos;
    },
    canInvest() {
      if (this.$data.project.monto_disponible == 0) {
        let myAmountToInvest = 0;

        if (
          this.$data.temporalInvestments != null &&
          this.$data.temporalInvestments.length > 0
        ) {
          myAmountToInvest = this.$data.temporalInvestments.reduce(
            (last: any, tmp: any) => last + tmp.monto,
            0
          );
        } 

        return myAmountToInvest - this.$data.amountToInvest > 0;
      } else {
        // @ts-ignore
        if (this.$data.amountToInvest < this.minAmountToInvest()) {
          return true;
        }

        // @ts-ignore
        if (this.$data.amountToInvest > this.maxAmountToInvest()) {
          return true;
        }

        return true;
      }
    },
    rights() {
      if (this.$data.paymentData.partnerState == 2) {
        // @ts-ignore
        return `${this.$options.filters.currency(
          0
        )} (Los socios no tienen que transferir derechos para reservar.)`;
      } else {
        // @ts-ignore
        return this.$options.filters.currency(
          this.$data.simulation.cobroDerechos,this.$data.project.proyecto_moneda.moneda.simbolo
        );
      }
    },
    additionalServicesAmount() {
      if ([0, 3].includes(this.$data.paymentData.clubState)) {
        return 0;
      } else {
        return RedCapitalConfig(SelectedCountry).Project.ClubAmount;
      }
    }
  },
  data() {
    return {
      // Constantes usables dentro del template
      disabledAmount:true,
      val_continuar:false,
      deparments: null,
      provinces: [],
      districts: [],
      formatted:null,
      RedCapital,
      SelectedCountry,
      Backend,
      momentum: moment,
      statistics: null,
      canShow: false,
      simulationError: false,
      respHeaders: [
        {
          text: "Nombre Deudor",
          value: "nombre"
        },
        {
          text: "Rut Deudor",
          value: "rut"
        },
        {
          text: "Folio",
          value: "folio"
        },
        {
          text: "Vencimiento",
          value: "fecha"
        },
        {
          text: "Monto",
          value: "monto"
        }
      ],
      rules: {
        notEmpty: [(v: any) => !!v || "Este campo es requerido"],
        account: [
          (v: any) => !!v || "Numero de cuenta es requerido",
          (v: any) =>
            !/[^\d]/gim.test(v) ||
            "La cuenta sólo debe tener números (Sin guiones)",
          (v: any) => v.length >= 6 || "Tiene que ser mayor o igual a 6"
        ],
        name: [
          (v: any) => !!v || "Nombre requerido",
          (v: any) => !/[0-9]/g.test(v) || "Nombre no puede contener numeros"
        ],
        lastname: [
          (v: any) => !!v || "Apellido requerido",
          (v: any) => !/[0-9]/g.test(v) || "Apellido no puede contener numeros"
        ],
        address: [(v: any) => !!v || "Dirección es requerido"],
        bank: [(v: any) => !!v || "Tienes que seleccionar un banco"],
        accountType: [
          (v: any) => !!v || "Tienes que seleccionar un tipo de cuenta"
        ],
        paymentType: [
          (v: any) => !!v || "Tienes que seleccionar un tipo de pago"
        ],
        phone: [
          (v: any) => !/[^\d]/gim.test(v) || "Sólo números",
          (v: any) => !!v || "Este campo es requerido",
          (v: any) => v.length >= 7 || "Ingresa datos correctos",
          ],
      },
      // Configuraciones globales
      blockbtn: false,
      maxAmount: null,
      btn: {
        personalData: {
          clickable: false,
          loading: false,
          isOk: null,
          successText: "Datos actualizados",
          errorText: "Error al actualizar"
        },
        reportPayment: {
          clickable: true,
          loading: false,
          isOk: null,
          successText: "Datos actualizados",
          errorText: "Error al actualizar"
        },
        simulation: {
          clickable: true,
          loading: false,
          isOk: null,
          successText: "Simular",
          errorText: "Error"
        }
      },
      // Dialogos
      showTemporalInvestmentsDialog: false,
      showUserLoginMessage: false,
      // Utilidades
      currentStep: 1,
      currencyDisabled: false,
      termsOfUse: false,
      firstTime: true,
      isLoadingPayments: false,
      // Monto que se muestra.
      amountToInvest: 0,
      project: null,
      simulation: null,
      temporalInvestment: null,
      temporalInvestments: null,
      initialInvestment: null,
      investment: {
        // Monto que se envia.
        amount: 0,
        isJoiningClub: false,
        isCancellingClub: false,
        isPartner: false,
        isReserving: false,
        isLastInvestment: false
      },
      paymentData: {
        phone:"",
        account: "",
        names: "",
        lastname: "",
        lastname2: "",
        rut: "",
        address: "",
        amount: 0,
        accountType: "",
        region: 0,
        commune: 0,
        bank: 0,
        isCancellingClub: false,
        isReserving: false,
        partnerState: 0,
        clubState: 2,
        isVIP: null,
        deparment_id:[],
        province_id:[],
        distrito_id:[]
      },
      reportPaymentData: {
        name: "",
        account: "",
        rut: "",
        bank: null,
        paymentType: null,
        amount: 0
      },
      reportPaymentTransaction: null,
      isInvesting: false,
      statics: {
        banks: null,
        regions: null,
        communes: null,
        accountTypes: null,
        paymentTypes: [
          {
            id: 1,
            text: "Solo reserva",
            disabled: false
          },
          {
            id: 2,
            text: "Abono",
            disabled: false
          },
          {
            id: 3,
            text: "Monto total",
            disabled: false
          }
        ]
      },
      isInvestmentPaymentDataValid: false,
      stepTabs: 1,
      isMobile: true,
      initialInvestmentCreated: false,
      lastAmount: 0
    };
  },
  watch: {
    amountToInvest() {
      // @ts-ignore
      this.simulate();
    },
    termsOfUse() {
      if (this.$refs.investmentPayment != null) {
        // @ts-ignore
        if (this.$refs.investmentPayment.validate()) {
          this.$data.isInvestmentPaymentDataValid = true;
          this.$data.btn.personalData.clickable =
            this.$data.termsOfUse && this.$data.isInvestmentPaymentDataValid;
        } else {
          this.$data.isInvestmentPaymentDataValid = false;
          this.$data.btn.personalData.clickable =
            this.$data.termsOfUse && this.$data.isInvestmentPaymentDataValid;
        }
      }
    },
    paymentData: {
      handler(val) {
        if (this.$refs.investmentPayment != null) {
          // @ts-ignore
          if (this.$refs.investmentPayment.validate()) {
            this.$data.isInvestmentPaymentDataValid = true;
            this.$data.btn.personalData.clickable =
              this.$data.termsOfUse && this.$data.isInvestmentPaymentDataValid;
          } else {
            this.$data.isInvestmentPaymentDataValid = false;
            this.$data.btn.personalData.clickable =
              this.$data.termsOfUse && this.$data.isInvestmentPaymentDataValid;
          }
        }
      },
      deep: true
    },
    currentStep(val) {
      // Esto se ejecuta cuando el usuario pasa a la pantalla de metodo de pago
      if (
        ([2, 3].includes(this.$data.paymentData.partnerState) &&
          this.$data.currentStep == 3) ||
        (this.$data.paymentData.partnerState != 2 &&
          this.$data.currentStep == 4)
      ) {
        // Solo se ejecuta la primera vez que entra
        if (this.$data.firstTime) {
          // @ts-ignore
          this.createInitialInvestment();
        }
      }
    },
    "reportPaymentData.paymentType": {
      
      handler(val) {
        switch (val) {
          case 1:
            this.$data.reportPaymentData.amount = this.$data.simulation.cobroDerechos;
            this.$data.maxAmount = this.$data.simulation.cobroDerechos;
            this.$data.currencyDisabled = true;
            break;
          case 2:
            // @ts-ignore
            this.$data.reportPaymentData.amount = this.amountToPay;
            // @ts-ignore
            this.$data.maxAmount = this.amountToPay;
            if (this.$data.paymentData.clubState == 1) {
              this.$data.reportPaymentData.amount += RedCapitalConfig(
                SelectedCountry
              ).Project.ClubAmount;
              this.$data.maxAmount += RedCapitalConfig(
                SelectedCountry
              ).Project.ClubAmount;
            }
            this.$data.currencyDisabled = false;
            break;
          case 3:
           
            // @ts-ignore
            this.$data.reportPaymentData.amount = this.amountToPay;
            // @ts-ignore
            this.$data.maxAmount = this.amountToPay;
            if (this.$data.paymentData.clubState == 1) {
              this.$data.reportPaymentData.amount += RedCapitalConfig(
                SelectedCountry
              ).Project.ClubAmount;
              this.$data.maxAmount += RedCapitalConfig(
                SelectedCountry
              ).Project.ClubAmount;
            }
            this.$data.currencyDisabled = true;
            break;
        }
        // if (val == 1) {
        //   this.$data.reportPaymentData.amount = this.$data.simulation.cobroDerechos
        // } else {
        //   // @ts-ignore
        //   this.$data.reportPaymentData.amount = this.amountToPay
        //   if (this.$data.paymentData.clubState == 1) {
        //     this.$data.reportPaymentData.amount += RedCapitalConfig(SelectedCountry).Project.ClubAmount
        //   }
        // }
      },
      deep: true
    }
  },
  created() {
    if (SelectedCountry == "PYME") {
      this.$router.push({
        path: "/"
      });
    }
  },
  async beforeMount() {
    let self = this;
    EchoInstance.channel(`proyecto${this.$props.projectId}`).listen(
      "ProyectInvestment",
      function(data: any) {
        // @ts-ignore
        self.updateAmounts();
      }
    );
    //@ts-ignore
    this.updateDeparments()
    if (this.$route.query.s == "t") {
      await API.getSecretProject(this.$props.projectId)
        .then((res: any) => {
          this.$data.project = res;
          this.$data.canShow = true;
        })
        .catch((err: any) => {
          this.$data.simulationError = true;
        });
    } else {
      await API.getProject(this.$props.projectId)
        .then((res: any) => {
          this.$data.project = res;
          console.log('project', res)
          this.$data.canShow = true;
        })
        .catch((err: any) => {
          this.$data.simulationError = true;
        });
    }

    if (this.$data.project.credito_tipo_id == 1) {
      API.getStatistics(this.$props.projectId)
        .then((res: any) => {
          this.$data.statistics = res;
        })
        .catch((err: any) => {
          console.log(err);
        });
    }

    if (this.$store.state.user != null) {
      this.$data.temporalInvestments = await API.getTemporalInvestments(
        this.$props.projectId
      );
    }

    await isUserLogin(
      async () => {
        // @ts-ignore
        let tmp = (
          await API.getTemporalInvestments(this.$props.projectId)
        ).reduce((last: any, current: any) => last + current.monto, 0);

        if (
          this.$data.project.monto_disponible + (tmp != undefined ? tmp : 0) <=
          0
        ) {
          /*this.$router.push({
          name: 'investment'
        })*/
        }
      },
      () => {
        if (this.$data.project.monto_disponible <= 0) {
          /*this.$router.push({
          name: 'investment'
        })*/
        }
      }
    );

    await isUserLogin(
      async () => {
        let projectsIds = (await API.getSecretProjects()).map(
          project => project.id
        );

        if (
          this.$data.project.proyecto_tipo_id == 3 &&
          !projectsIds.includes(this.$data.project.id)
        ) {
          this.$router.push({
            name: "investment"
          });
        }
      },
      async () => {
        /*if ([2, 3].includes(this.$data.project.proyecto_tipo_id)) {
        this.$router.push({
          name: 'investment'
        })
      }*/
      }
    );
    let montoTemporales =
      this.$data.temporalInvestments != null &&
      this.$data.temporalInvestments.length > 0
        ? this.$data.temporalInvestments.reduce(
            (last: any, current: any) => last + current.monto,
            0
          )
        : 0;

    if (this.$data.project.monto_disponible + montoTemporales == 0) {
      // @ts-ignore
      this.$data.amountToInvest = this.minAmountToInvest();
    } else {
      // @ts-ignore
      this.$data.amountToInvest = this.minAmountToInvest();
    }

    this.$data.investment.amount = this.$data.amountToInvest;
    // @ts-ignore
    this.simulate();
    // @ts-ignore
    this.updateAmounts();
    // @ts-ignore
    this.loadData();

    EventBus.$on("login-successfully", () => {
      // @ts-ignore
      this.loadData();
      this.$data.showUserLoginMessage = false;
    });

    // @ts-ignore
    if (!this.canReserve) {
      this.$data.statics.paymentTypes[0].disabled = true;
    }
    if (this.$data.paymentData.partnerState == 1) {
      this.$data.paymentData.clubState = 2;
    } else {
      this.$data.paymentData.clubState = 2;
    }
  },
  beforeDestroy() {
    EventBus.$off("login-successfully");
    EchoInstance.disconnect();
  },
  methods: {
    mofifyInvesmet(){
        API.deleteTemporalInvestments(this.$data.project.id)
        this.$data.disabledAmount = true
        this.$data.isInvesting = false;
        this.$data.btn.simulation.clickable = true;
        
        this.$data.currentStep = 1
    },
    async updateDeparments() { //updateDeparments
      this.$data.deparments = await API.getDeparments();

      if (this.$data.paymentData.deparment_id == null) {
        this.$data.paymentData.deparment_id = this.$data.deparments[0].deparment_id;
      }

      // @ts-ignore
      this.updateProvinces();
    },
    async updateProvinces() { //updateProvinces
    
      this.$data.provinces = await API.getProvinces(
        this.$data.paymentData.deparment_id
      );

      if (this.$data.provinces.length != 0) {
        if (this.$data.paymentData.province_id == null) {
          this.$data.paymentData.province_id = this.$data.provinces[0].province_id;
        }
      }
      this.$data.canShow = true;
      // @ts-ignore
      this.updateDistricts();
    },
    async updateDistricts() { //updateDistricts
      this.$data.districts = await API.getDistricts(
        this.$data.paymentData.province_id
      );
      if (this.$data.districts.length != 0) {
        if (this.$data.userData.distrito_id == null) {
          this.$data.userData.distrito_id = this.$data.districts[0].district_id;
        }
      }
      this.$data.canShow = true;
      // @ts-ignore
      // this.updateProvinces();
    },
    checkAmount() {
      // DUMMY
    },
    onResize() {
      if (window.innerWidth <= 960) {
        this.$data.isMobile = true;
      } else {
        this.$data.isMobile = false;
      }
    },
    openLoginDialog() {
      EventBus.$emit("open-login-dialog", Uri.string(this.$route.path));
    },
    investNow() {
      isUserLogin(
        async () => {
          this.$data.formatted = this.$store.state.user.rut;
          this.$data.canShow = true;
          const format = this.$store.state.user.rut.split('') 
        /*  if (format.length == 10) {
            this.$data.formatted = `${format[0]}${format[1]}.${format[2]}${format[3]}${format[4]}.${format[5]}${format[6]}${format[7]}${format[8]}${format[9]}`
          }else{
            this.$data.formatted = `${format[0]}.${format[1]}${format[2]}${format[3]}.${format[4]}${format[5]}${format[6]}${format[7]}${format[8]}`
          }*/
          // @ts-ignore
          this.updateAmounts();
          this.$data.temporalInvestments = await API.getTemporalInvestments(
            this.$props.projectId
          );
          this.$data.investment.amount = this.$data.amountToInvest;

          // @ts-ignore
          this.simulate();

          if (this.$data.temporalInvestments.length == 0) {
            // No existe ninguna temporal
            // @ts-ignore
            this.createTemporalInvestment()
              .then(() => {
                this.$data.isInvesting = true;
                this.$data.btn.simulation.clickable = false;
              })
              .catch(() => {
                EventBus.$emit(
                  "error-dialog",
                  true,
                  "Error al crear la inversión"
                );
              });
          } else {
            // Existen inversiones temporales

            // @ts-ignore
            let tmp = await this.existATemporal(this.$data.amountToInvest);

            if (tmp == undefined) {
              // No existe ninguna otra inversion igual a este monto

              await API.deleteTemporalInvestments(this.$data.project.id);

              // @ts-ignore
              this.createTemporalInvestment()
                .then(() => {
                  this.$data.btn.simulation.clickable = false;
                  this.$data.isInvesting = true;
                })
                .catch(() => {
                  EventBus.$emit(
                    "error-dialog",
                    true,
                    "Error al crear la inversión"
                  );
                });
            } else {
              // Si existe una igual entonces
              this.$data.temporalInvestment = tmp;

              this.$data.isInvesting = true;
              this.$data.btn.simulation.clickable = false;
            }
          }
        },
        () => {
          EventBus.$emit("open-login-dialog", Uri.string(this.$route.path));
          // aca
          // this.$data.showUserLoginMessage = true
        }
      );
    },
    async createTemporalInvestment() {
      let tmp = {
        project_id: this.$data.project.id,
        amount: this.$data.investment.amount
      };

      API.createTemporalInvestment(tmp)
        .then(async (data: any) => {
          this.$data.temporalInvestments = await API.getTemporalInvestments(
            this.$props.projectId
          );
          this.$data.temporalInvestment = data.data.data;
          // @ts-ignore
          this.updateAmounts();
          this.$data.disabledAmount = false
        })
        .catch(error => {
          console.log(error);
        });
    },
    async createInitialInvestment() {
      let isPartner = this.$data.paymentData.partnerState == 2 ? true : false;

      if (this.$data.temporalInvestment != null) {
        let code = await API.getUniqueCode();
        this.$store.commit("setLastCod", {
          cod: code,
          id: this.$props.projectId
        });

        API.createInitialInvestment({
          projectId: this.$data.project.id,
          amount: this.$data.investment.amount,
          isReserving: this.$data.paymentData.isReserving,
          isPartner: isPartner,
          isCancellingClub: this.$data.paymentData.clubState == 3,
          isJoiningClub: isPartner ? 0 : this.$data.paymentData.clubState == 1,
          investmentId: this.$data.temporalInvestment.id,
          code
        })
          .then((res: any) => {
            this.$data.initialInvestment = res.data.data;
            this.$data.firstTime = false;
            this.$data.initialInvestmentCreated = true;

            // @ts-ignore
            this.updateAmounts();
            API.getTemporalInvestments(this.$props.projectId).then(data => {
              this.$data.temporalInvestments = data;
            });
          })
          .catch(error => {
            EventBus.$emit("error-dialog", true, "Error al crear la inversión");
            this.$data.currentStep -= 1;
          })
          .finally(() => {
            this.$data.isLoadingPayments = false;
          });
      } else {
        console.log("El codigo no puede llegar hasta aqui...");
      }
    },
    createPayment(gatewayId: number) {
      switch (gatewayId) {
        case 1:
          // @ts-ignore
          this.createTransferencePayment();
          break;
        case 2:
        case 3:
          // @ts-ignore
          this.createGatewayPayment(gatewayId);
          break;
      }
    },
    async createTransferencePayment() {
      if (this.$data.reportPaymentTransaction == null) {
        let isPartner = this.$data.paymentData.partnerState == 2 ? true : false;

        API.payment
          .create({
            projectId: this.$data.project.id,
            subject: this.$data.project.nombre,
            amount: this.$data.investment.amount,
            isReserving: this.$data.paymentData.isReserving,
            isJoiningClub: this.$data.paymentData.clubState == 1,
            isPartner: isPartner,
            investmentId: this.$data.initialInvestment.id,
            paymentGateway: 1
          })
          .then((data: any) => {
            this.$data.reportPaymentTransaction = data.transaccion;
            this.$store.commit("setGatewayTransaction", data.transaccion);
            this.$data.reportPaymentData.name = `${this.$data.paymentData.names} ${this.$data.paymentData.lastname} ${this.$data.paymentData.lastname2}`;
            this.$data.reportPaymentData.account = AccountTrim.string(
              this.$data.paymentData.account
            );
            this.$data.reportPaymentData.rut = this.$store.state.user.rut;
            this.$data.reportPaymentData.bank = this.$data.paymentData.bank;
            this.$data.reportPaymentData.paymentType = this.$data.paymentData
              .isReserving
              ? 1
              : 3;
            this.$data.reportPaymentData.code = data.transaccion.cod;
            this.$data.currentStep++;
          })
          .catch(error => {
            EventBus.$emit("error-dialog", true, "Error en la transferencia");
          });
      } else {
        this.$data.currentStep++;
      }
    },
    async createGatewayPayment(gatewayId: number) {
      if (this.$data.initialInvestment != null) {
        let isPartner = this.$data.paymentData.partnerState == 2 ? true : false;

        API.payment
          .create({
            projectId: this.$data.project.id,
            subject: this.$data.project.nombre,
            amount: this.$data.amountToInvest,
            isReserving: this.$data.paymentData.isReserving,
            isJoiningClub: this.$data.paymentData.clubState == 1,
            isPartner: isPartner,
            investmentId: this.$data.initialInvestment.id,
            paymentGateway: gatewayId
          })
          .then((data: any) => {
            if (data.payment_url) {
              this.$store.commit("setGatewayTransaction", data.transaccion);
              window.location.href = data.payment_url;
            } else {
              this.$router.push({
                path: "/reserva-exitosa"
              });
            }
          });
      }
    },
    async payLater() {
      API.payment.report
        .tracking(1, this.$data.reportPaymentTransaction.inversion_id)
        .then((res: any) => {
          this.$router.push({
            name: "dashboard-investor-pending-payments"
          });
        })
        .catch((err: any) => {
          console.log(err);
        });
    },
    async reportPayment() {
      let payload = {
        cod: this.$data.reportPaymentTransaction.cod,
        investmentId: this.$data.initialInvestment.id,
        bank: this.$data.reportPaymentData.bank,
        accountNumber: AccountTrim.string(this.$data.reportPaymentData.account),
        name: this.$data.reportPaymentData.name,
        dni: this.$data.reportPaymentData.rut,
        paymentType: this.$data.reportPaymentData.paymentType,
        date: moment(Date.now()).format("YYYY-MM-DD")
      };
      API.payment.report
        .tracking(2, this.$data.reportPaymentTransaction.inversion_id)
        .then((res: any) => {
          console.log('API.payment.report',res);
        })
        .catch((err: any) => {
          console.log(err);
        });
      API.payment.report
        // @ts-ignore
        .transference(this.$data.project.id,  this.$data.reportPaymentData.amount, payload)
        .then((res: any) => {
          this.$store.commit("setCurrentInvestment", {
            investmentId: this.$data.initialInvestment.id,
            isLastInvestment: this.$data.investment.isLastInvestment,
            proyectId: this.$props.projectId
          });
          // FIXME: Crear la ruta para esto...
          this.$router.push({
            name: "thanks-for-investing"
          });
        })
        .catch(error => {
          console.log(error);
        })
        .finally(() => {
          this.$data.transactionCompleted = true;
        });
    },
    nextStepClub(isJoiningClub: boolean) {
      this.$data.paymentData.clubState = isJoiningClub ? 1 : 2;
      this.$data.currentStep += 1;
    },
    async simulate() {
      // @ts-ignore
      if (
        // @ts-ignore
        this.$data.amountToInvest >= this.minAmountToInvest() &&
        // @ts-ignore
        this.$data.amountToInvest <= this.maxAmountToInvest()
      ) {
        this.$data.lastAmount = this.$data.amountToInvest;
        this.$data.btn.simulation.loading = true;
        // FIXME:
        // @ts-ignore
        this.$data.investment.isLastInvestment =
          // @ts-ignore
          this.$data.amountToInvest == this.maxAmountToInvest();
        this.$data.simulation = await API.simulate(
          this.$props.projectId,
          this.$data.amountToInvest,
          this.$data.investment.isLastInvestment,
          this.$store.state.user != null ? this.$store.state.user.id : null
        );
      /*  if (this.$data.simulation.cobroDerechos == 0) {
          this.$data.simulation.cobroDerechos = 1;
        }*/
        setTimeout(() => {
          this.$data.btn.simulation.loading = false;
          this.$data.btn.simulation.isOk = true;
        }, 300);
      }
    },
    async updateAmounts() {
      let amounts: any = await API.getProjectAmounts(this.$props.projectId);
      this.$data.project.updateAmounts(
        amounts.get_monto_proyecto,
        amounts.get_monto_proyecto_inversion_tmp,
        amounts.get_monto_reserva,
        amounts.monto_disponible
      );
    },
    async existATemporal(amount: number) {
      // @ts-ignore
      let temporalInvestments: Array<any> = await API.getTemporalInvestments(
        this.$props.projectId
      );
      return temporalInvestments.find((tmp: any) => tmp.monto == amount);
    },
    minAmountToInvest() {
      let myAmountToInvest = 0;

      if (
        this.$data.project.monto_disponible <
        RedCapitalConfig(SelectedCountry).Project.MinInvest
      ) {
        if (
          this.$data.temporalInvestments != null &&
          this.$data.temporalInvestments
            .map((tmp: any) =>  parseFloat(tmp.monto))
            .reduce((last: number, current: number) => last + current, 0) >= 0
        ) {
          return (
            this.$data.project.monto_disponible +
            this.$data.temporalInvestments
              .map((tmp: any) => tmp.monto)
              .reduce((last: number, current: number) => last + current, 0)
          );
        } else {
          return this.$data.project.monto_disponible;
        }
      } else {
        return RedCapitalConfig(SelectedCountry).Project.MinInvest;
      }
    },
    maxAmountToInvest() {
      if (
        this.$data.temporalInvestments != null &&
        this.$data.temporalInvestments.length > 0
      ) {
        let myAmounts = 0;
        myAmounts += this.$data.temporalInvestments
          .map((tmp: any) => parseFloat(tmp.monto))
          .reduce((last: number, current: number) => last + current, 0);
        // @ts-ignore
        return this.$data.project.monto_disponible + myAmounts;
      } else {
        return this.$data.project.monto_disponible;
      }
    },
    // async updateCommunes() {
    //   let commune = this.$data.statics.regions.find(
    //     (el: any) => el.id == this.$data.paymentData.region
    //   );
    //   if (commune.id != null || commune.id != undefined) {
    //     this.$data.statics.communes = await API.getCommunes(commune.id);
    //   } else {
    //     this.$data.statis.communes = await API.getCommunes(13);
    //   }
    //   // this.$data.paymentData.commune = this.$data.statics.communes[0].id
    // },
    updatePersonalData() {
      this.$data.val_continuar = false;
      let user: any = {};
       Object.assign(user, this.$store.state.user)
      this.$data.paymentData.rut = this.$store.state.user.rut;

      user.rut = this.$data.paymentData.rut;
      user.nombre = this.$data.paymentData.names;
      user.telefono = this.$data.paymentData.phone;
      user.app_pat = this.$data.paymentData.lastname;
      user.app_mat = this.$data.paymentData.lastname2;
    //  user.region_id = this.$data.paymentData.region;
     // user.comuna_id = this.$data.paymentData.commune;
      user.direccion = this.$data.paymentData.address;


      this.$data.btn.personalData.loading = true;

      Promise.all([
        API.updateBankAccount(
          this.$data.paymentData.account,
          this.$data.paymentData.bank,
          this.$data.paymentData.accountType
        ),
        API.updatePersonalData(user)
      ])
        .then(values => {
          this.$data.btn.personalData.isOk = true;
          API.getPersonalData(this.$store.state.user.id)
            .then(user => {
              this.$store.commit("setUser", user);
            })
            .catch(error => {
              // OOHH NO, NO PUEDE SER.
            });
        })
        .catch(reason => {
          this.$data.btn.personalData.isOk = false;
        })
        .finally(() => {
          this.$data.btn.personalData.clickable = true;
          setTimeout(() => {
            this.$data.currentStep += 1;
          }, this.$data.btn.personalData.delay);
        });
    },
    async loadData() {
      await isUserLogin(async () => {
        this.$data.paymentData.rut = this.$store.state.user.rut;
        this.$data.paymentData.phone = this.$store.state.user.telefono
        this.$data.paymentData.names = this.$store.state.user.nombre;
        this.$data.paymentData.lastname = this.$store.state.user.app_pat;
        this.$data.paymentData.lastname2 = this.$store.state.user.app_mat;
        this.$data.paymentData.distrito_id = this.$store.state.user.distrito_id;
       // this.$data.paymentData.commune = this.$store.state.user.comuna_id;
        this.$data.paymentData.address = this.$store.state.user.direccion;
        this.$data.paymentData.partnerState = 1;

        API.getDistrito_id(this.$data.paymentData.distrito_id)
              .then((res =>{
                //@ts-ignore
                this.$data.paymentData.deparment_id = res.data.selected.departamento_id
                //@ts-ignore
               // console.log(res.data.selected.id)
                //@ts-ignore
                this.$data.paymentData.province_id = res.data.selected.id
                 API.getDistricts(
                   //@ts-ignore
                    res.data.selected.id
                  )
                  .then((res)=>{
                    this.$data.districts = res
                  })
                API.getProvinces(
                  //@ts-ignore
                    res.data.selected.departamento_id
                  ).then((res)=>{
                    this.$data.provinces = res
                  })
              }))
              .catch((error)=>{
                console.log(error)
              })

        let partner: any = await API.getClub();
      
      if (partner != null) {
         // this.$data.paymentData.partnerState = partner.estado_socio_id;
          if (partner.estado_socio_id == 1) {
            this.$data.paymentData.clubState = 2;
          }
          if (partner.estado_socio_id == 2) {
            this.$data.paymentData.clubState = 2;
          }
          if (partner.estado_socio_id == 3) {
            this.$data.paymentData.clubState = 2;
          }
        }
        if (!partner) {
          this.$data.paymentData.partnerState = 1;
        }
      });

      this.$data.statics.banks = await API.getBanks();

      this.$data.statics.accountTypes = await API.getAccountTypes();

      isUserLogin(() => {
        API.getUserBank(this.$store.state.user.id)
          .then((bankAccount: any) => {
            this.$data.paymentData.account = bankAccount.numero;
            this.$data.paymentData.bank = bankAccount.banco_id;
            this.$data.paymentData.accountType = bankAccount.tipo_cuenta_id;
          })
          .catch(() => {
            // No tiene una cuenta bancaria creada. resuelvo como puedas
          });
      });

      this.$data.paymentData.isVIP = await API.getVIP(
        this.$store.state.user.id
      );

      // this.$data.statics.regions = await API.getRegions();
      // @ts-ignore
      // this.updateCommunes();
    },
    async reserveNow() {
      this.$data.blockbtn = true;
      let isPartner = this.$data.paymentData.partnerState == 2 ? true : false;

      if (this.$data.temporalInvestment != null) {
        let code = await API.getUniqueCode();

        API.createInitialInvestment({
          projectId: this.$data.project.id,
          amount: this.$data.investment.amount,
          isReserving: this.$data.paymentData.isReserving,
          isPartner: isPartner,
          isCancellingClub: this.$data.paymentData.clubState == 3,
          isJoiningClub: isPartner ? 0 : this.$data.paymentData.clubState == 1,
          investmentId: this.$data.temporalInvestment.id,
          code
        })
          .then((res: any) => {
            this.$data.initialInvestment = res.data.data;
            this.$data.firstTime = false;
            this.$data.initialInvestmentCreated = true;

            // @ts-ignore
            this.updateAmounts();
            API.getTemporalInvestments(this.$props.projectId).then(data => {
              this.$data.temporalInvestments = data;
              this.$store.commit("setCurrentInvestment", {
                investmentId: this.$data.initialInvestment.id,
                isLastInvestment: this.$data.investment.isLastInvestment,
                proyectId: this.$props.projectId
              });
              EventBus.$emit("snack-success", "Reserva exitosa");
              setTimeout(() => {
                this.$router.push({
                  path: "/gracias-por-invertir"
                });
              }, Configuration.Redirection.timeout);
            });
          })
          .catch(error => {
            console.log(error);
            this.$data.blockbtn = false;
            EventBus.$emit("error-dialog", true, "Error al crear la inversión");
            this.$data.currentStep -= 1;
          })
          .finally(() => {
            this.$data.isLoadingPayments = false;
          });
      } else {
        console.log("El codigo no puede llegar hasta aqui...");
      }
    }
  },
  components: {
    CurrencyInput,
    DniField,
    ProgressBar,
    ReusableButton,
    PaymentGateways
  }
})
export default class Simulation extends Vue {
  @Prop() projectId!: number;
}
